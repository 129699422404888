import { Entry } from "contentful";
import Head from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";

import CrossCountry from "~/types/cross-country";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import { isActiveEntry } from "~/utils/editorial-utils";

type Props = {
  entry: Entry<Editorials.SeoMetadata>;
  titleSuffix?: string;
  productDetail?: CrossCountry.DetailedProduct;
  productDescription?: string;
  canonical?: string;
  hreflangs?: { locale: string; url: string }[];
};

export default function SeoHead(props: Props) {
  const router = useRouter();
  const robots = useMemo(() => {
    if (props.entry.fields?.no_index && props.entry.fields?.no_follow) {
      return "none";
    } else if (props.entry.fields?.no_index) {
      return "noindex";
    } else if (props.entry.fields?.no_follow) {
      return "nofollow";
    } else {
      return "all";
    }
  }, [props.entry.fields?.no_index, props.entry.fields?.no_follow]);

  if (!isActiveEntry(props.entry)) return null;

  const keywords = Array.isArray(props.entry.fields.keywords)
    ? props.entry.fields.keywords.join(", ")
    : props.entry.fields.keywords;

  const getTitle = (product?: CrossCountry.DetailedProduct) => {
    const titleSuffix = props.entry.fields.noTitleSuffix ? "" : ` | ${props.titleSuffix}`;

    if (product) {
      /*
        PDP title in order of priority
        1. SEO Metadata from Contentful (no "generic_pdp_seo") + suffix
        2. Attribute marketing_title
        3. Attribute display_value + suffix
        4.SEO Metadata from Contentful of "generic_pdp_seo" + suffix
      */

      let seoTitle = "";

      if (props.entry.fields.seoTitle) {
        seoTitle = props.entry.fields.seoTitle;
        if (props.entry.fields.name !== Constants.GENERIC_PDP_SEO) {
          return seoTitle + titleSuffix;
        }
      }

      if (product.marketing_title) {
        return product.marketing_title;
      } else if (product.display_value) {
        return product.display_value + titleSuffix;
      } else {
        return seoTitle + titleSuffix;
      }
    } else {
      return props.entry.fields.seoTitle + titleSuffix;
    }
  };

  return (
    <Head key={router.asPath}>
      <title>{getTitle(props.productDetail)}</title>
      <meta name="description" content={props.productDescription ?? props.entry.fields.description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content={robots} />
      {props.canonical && <link rel="canonical" href={props.canonical} />}
      {props.hreflangs?.map((hreflang, index) => (
        <link key={index} rel="alternate" hrefLang={hreflang.locale} href={hreflang.url} />
      ))}
    </Head>
  );
}
